import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { takeUntil } from 'rxjs';
import { TakeUntilDestroy } from 'src/app/shared/directives/take-until-destory.directive';
import {  ServerErrorMessage } from 'src/app/util/common.constants';
import { MaterialIcon } from 'src/app/util/common.enum';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signup-invite-form-view',
  templateUrl: './signup-invite-form-view.component.html',
  styleUrls: ['./signup-invite-form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupInviteFormViewComponent extends TakeUntilDestroy implements OnInit {

  title = 'Signup';
  form: FormGroup;
  formControls: any;
  isLoading: boolean;
  submitIcon: string;
  cancelIcon: string;

  constructor(
    private router: Router,
    private formService: AuthFormService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private messageService: NzNotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.submitIcon = MaterialIcon.ARROW_CIRCLE_RIGHT;
    this.cancelIcon = MaterialIcon.CLOSE_NORMAL;
    this.form = this.formService.buildForm();
    this.formControls = this.formService.getFormControls(this.form);
  }

  navigateToLogin() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  onInviteSubmit() {
    this.isLoading = true;
    const { email } = this.form.getRawValue();
    this.authService.invite(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.isLoading = false;
        this.cdr.markForCheck();
        localStorage.clear();
        if (data.success) {
          this.messageService.create('success',  'Success', `Invitation link successfully sent to ${email}`, { nzClass: 'success' });
          this.router.navigate(['/login']);
        } else if (data.exists) {
          this.messageService.create('warning',  'Alert', `${email} already exists. Please login.`, { nzClass: 'warning' })
        } else {
          const message = data.message ? data.message : ServerErrorMessage;
          this.messageService.create('error',  'Error', message, { nzClass: 'error' });
        }
      })
  }

}
