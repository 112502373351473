import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { checkIfValuesMatching } from 'src/app/validators/validators';
import { AuthCodeModel, AuthFormModel, LoginFormModel, ResetFormModel, SignupFormModel } from '../model/auth.model';

@Injectable()
export class AuthFormService {

  constructor(private fb: FormBuilder) { }

  buildLoginForm(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]]
    })
  }

  buildForm(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  buildCodeForm(): FormGroup {
    return this.fb.group({
      code: [null, [Validators.required]]
    })
  }

  buildSignupForm(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
      code:[null, [Validators.required]],
      name:[null, [Validators.required, Validators.minLength(3)]],
      companyName: [null, [Validators.required, Validators.minLength(3)]],
      companyId:[null],
    },{
      validators: checkIfValuesMatching('password', 'confirmPassword')
    })
  }

  buildResetPasswordForm(): FormGroup {
    return this.fb.group({
      email: [{ value: null, disabled: true }, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
      code:[null, [Validators.required]]
    },{
      validators: checkIfValuesMatching('password', 'confirmPassword')
    })
  }

  getFormControls(form: FormGroup): { [key in keyof (AuthFormModel | LoginFormModel | ResetFormModel)]: FormControl } {
    return form.controls as { [key in keyof (AuthFormModel | LoginFormModel | ResetFormModel)]: FormControl };
  }

  getCodeFormControls(form: FormGroup): { [key in keyof AuthCodeModel]: FormControl } {
    return form.controls as { [key in keyof AuthCodeModel]: FormControl };
  }

  setSignupFormValues(form: FormGroup, formData: SignupFormModel) {
    form.get('email')?.disable();
    if (formData.companyName) {
      form.get('companyName')?.disable();
    }
    form.patchValue(formData);
  }

  setResetPasswordValues(form: FormGroup, formData: any) {
    form.patchValue(formData);
  }

  makeFieldOptional(form: FormGroup, fieldName: string): void {
    form.get(fieldName)?.setValidators(null);
    form.get(fieldName)?.updateValueAndValidity();
  }

  makeFieldRequired(form: FormGroup, fieldName: string): void {
    form.get(fieldName)?.setValidators([Validators.required, Validators.minLength(6)]);
    form.get(fieldName)?.updateValueAndValidity();
  }
}
