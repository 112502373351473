<div class="bg-base h-full block">
  <div class="block relative top-20">
    <div class="flex justify-center mb-6">
      <img src="../../assets/images/supercmms-white.png" class="logo" alt="Super Cmms" />
    </div>
    <div class="block text-center mb-2">
      <span class="font-bold uppercase text-lg">{{ title }}</span>
    </div>
    <app-signup-invite [form]="form" [formControls]="formControls" [cancelIcon]="cancelIcon" [submitIcon]="submitIcon"
      [isSubmitting]="isLoading" (navigateToLogin)="navigateToLogin()" (submitInvite)="onInviteSubmit()">
    </app-signup-invite>
  </div>
</div>
