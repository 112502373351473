import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordFormViewComponent } from './reset-password-form-view.component';
import { ResetPasswordModule } from '../../components/reset-password/reset-password.module';

@NgModule({
  declarations: [
    ResetPasswordFormViewComponent
  ],
  imports: [
    CommonModule,
    ResetPasswordModule
  ],
  exports: [ResetPasswordFormViewComponent]
})
export class ResetPasswordFormViewModule { }
