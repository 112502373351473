import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupInviteComponent } from './signup-invite.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    SignupInviteComponent
  ],
  imports: [
    CommonModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    FormsModule,
    NzButtonModule,
    NzToolTipModule,
    NzSpinModule
  ],
  exports: [
    SignupInviteComponent
  ]
})
export class SignupInviteModule { }
