import { TextValueModel } from "./common.model";

export const PriorityData: TextValueModel[] = [
  {
    value: 'NORMAL',
    text: 'Normal'
  },
  {
    value: 'URGENT',
    text: 'Urgent'
  },
];

export const MessageDuration = 3000;
export const ServerErrorMessage = `Something went wrong. Please try again later.`;
export const ValidTokenErrorMessage = 'This invitation is either invalid or expired.';
export const NoAccessMessageLine1 = 'You do not have access to this section.';
export const NoAccessMessageLine2 = 'Please contact your Admin.';

export const AppFeatures = {
  assets: 'Assets and Inventory',
  // maintenance: 'Maintenance Master',
  workOrder: 'Work Orders',
  // billing: 'Billing'
}

export const AllowedFormats = ['png', 'jpeg', 'jpg', 'svg', 'tiff', 'bmp', 'gif', 'ico', 'webp', 'avi', 'mp4', 'mpeg', 'ogv', 'webm', '3gp', 'mov', 'pdf', 'xls', 'xlsx', 'csv', 'doc', 'docx', 'ppt', 'pptx'];
export const AllowedImageFormats = ['png', 'jpeg', 'jpg', 'svg', 'tiff', 'bmp', 'gif', 'ico', 'webp'];
export const AllowedVideoFormats = ['avi', 'mp4', 'mpeg', 'ogv', 'webm', '3gp', 'mov'];
export const AllowedDocumentFormats = ['pdf', 'xls', 'xlsx', 'csv', 'doc', 'docx'];
export const MaxImageUploadSize = 0.5;
export const MaxFileUploadSize = 25;
export const CustomImageFormats = ['png', 'jpeg', 'jpg'];
export const CategoryColors = [
  '55557B',
  'F0D717',
  '34B7EF',
  'FF6666',
  '4374F2',
  '00BD56',
  'FF90B3',
  '9966FF',
  '3BEBE4',
  'FCBE79',
  'AC9969',
  '85EF47',
];
export const FreeUserLimit = 5;