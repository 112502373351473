import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { defaultValidatorOptions, ElementPositionModel, HostModel, PositionModel, ValidatorOptionsModel } from '../model/password-validator.model';
import { PasswordValidatorService } from '../services/password-validator.service';
import { PasswordValidatorComponent } from '../password-validator.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[PasswordValidator]',
  exportAs: 'PasswordValidator',
})
export class PasswordValidatorDirective implements OnDestroy, OnChanges {
  regExpForLength = /^(.){8}$/;
  regExpForOneUpper = /^(?=.*[A-Z])(.*)$/;
  regExpForOneLower = /^(?=.*[a-z])(.*)$/;
  regExpForOneDigit = /^(?=.*[0-9])(.*)$/;
  regExpForSpecialCharacters = /^(?=.*[!@#$%^&*])([a-zA-Z0-9!@#$%^&*]*)$/;

  isValid = false;
  inputValue = '';
  componentRef: any;
  elementPosition: ElementPositionModel;
  passwordOptions: ValidatorOptionsModel | any;
  componentSubscribe: Subscription;

  @Input('PasswordValidator') popup: ValidatorOptionsModel;
  @Input() initOptions: any;

  @Output() events: EventEmitter<any> = new EventEmitter<any>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private service: PasswordValidatorService,
    private injector: Injector
  ) {}

  get options() {
    return this.passwordOptions;
  }

  get isPopupDestroyed(): boolean {
    return this.componentRef && this.componentRef.hostView.destroyed;
  }

  get popupPosition(): ElementPositionModel | PositionModel | any{
    if (this.options['position']) {
      return this.options['position'];
    } else {
      return this.elementPosition;
    }
  }

  @HostListener('focusin', ['$event.target.value'])
  onMouseEnter(value: any): void {
    this.updatePasswordOptions();
    this.show();
    this.checkPassword(value);
  }

  @HostListener('focusout')
  onMouseLeave(): void {
    // If the template type is inline, don't destroy the created template
    if (this.passwordOptions.type !== 'inline') {
      this.destroyPopup();
    }
    this.valid.emit(this.isValid);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.checkPassword(value);
  }

  ngOnChanges(changes: { popup: SimpleChange }): void {
    // If the template type is 'inline' create the inline template directly
    const templateType = changes.popup.currentValue.type;
    if (templateType === 'inline') {
      this.updatePasswordOptions();
      this.show();
    }
    const changedOptions = this.getProperties(changes);
    this.applyOptionsDefault(changedOptions, defaultValidatorOptions);
  }

  ngOnDestroy(): void {
    this.destroyPopup();
    if (this.componentSubscribe) {
      this.componentSubscribe.unsubscribe();
    }
  }

  createPasswordRegex(): void {
    if (this.passwordOptions?.rules?.password) {
      switch (this.passwordOptions.rules['password'].type) {
        case 'number':
          this.regExpForLength = new RegExp(
            `^(.){${this.passwordOptions.rules['password'].length}}$`
          );
          break;
        case 'min':
          this.regExpForLength = new RegExp(
            `^(.){${this.passwordOptions.rules['password'].min},}$`
          );
          break;
        case 'range':
          this.regExpForLength = new RegExp(
            `^(.){${this.passwordOptions.rules['password'].min},${this.passwordOptions.rules['password'].max}}$`
          );
      }
    }
  }

  checkPassword(inputValue: string): void {
    const data = {
      password:
        inputValue &&
        inputValue.length &&
        inputValue.match(this.regExpForLength)
          ? true
          : false,
      'include-symbol':
        inputValue &&
        inputValue.length &&
        inputValue.match(this.regExpForSpecialCharacters)
          ? true
          : false,
      'include-number':
        inputValue &&
        inputValue.length &&
        inputValue.match(this.regExpForOneDigit)
          ? true
          : false,
      'include-lowercase-characters':
        inputValue &&
        inputValue.length &&
        inputValue.match(this.regExpForOneLower)
          ? true
          : false,
      'include-uppercase-characters':
        inputValue &&
        inputValue.length &&
        inputValue.match(this.regExpForOneUpper)
          ? true
          : false,
    } as any;

    for (const propName in this.passwordOptions.rules) {
      if (!this.passwordOptions.rules[propName]) {
        delete data[propName];
      }
    }
    this.isValid = Object.values(data).every((value) => value);
    this.valid.emit(this.isValid);
    this.service.updateValue(data);
  }

  updatePasswordOptions(): void {
    if (this.popup && defaultValidatorOptions) {
      this.passwordOptions = this.service.deepMerge(
        defaultValidatorOptions,
        this.popup
      );
    } else {
      this.passwordOptions = { ...defaultValidatorOptions };
    }
    this.createPasswordRegex();
  }

  getProperties(changes: { popup: SimpleChange } | any): { popup: any } {
    const directiveProperties: any = {};
    let customProperties: any = {};
    let allProperties: any = {};

    for (const prop in changes) {
      if (prop !== 'options') {
        directiveProperties[prop] = changes[prop].currentValue;
      }
      if (prop === 'options') {
        customProperties = changes[prop].currentValue;
      }
    }

    allProperties = Object.assign({}, customProperties, directiveProperties);

    return allProperties;
  }

  getElementPosition(): void {
    this.elementPosition =
      this.elementRef.nativeElement.getBoundingClientRect();
  }

  createPopup(): void {
    this.getElementPosition();
    this.appendComponentToBody(PasswordValidatorComponent);
    this.showPopupElem();
  }

  destroyPopup(): void {
    if (!this.isPopupDestroyed) {
      this.hidePopup();

      if (!this.componentRef || this.isPopupDestroyed) {
        return;
      }

      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.events.emit({
        type: 'hidden',
        position: this.popupPosition,
      });
    }
  }

  showPopupElem(): void {
    (this.componentRef.instance as HostModel).show = true;
    this.events.emit({
      type: 'show',
      position: this.popupPosition,
    });
  }

  hidePopup(): void {
    if (!this.componentRef || this.isPopupDestroyed) {
      return;
    }
    (this.componentRef.instance as HostModel).show = false;
    this.events.emit({
      type: 'hide',
      position: this.popupPosition,
    });
  }

  appendComponentToBody(component: any): void {
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    (this.componentRef.instance as HostModel).data = {
      element: this.elementRef.nativeElement,
      elementPosition: this.popupPosition,
      options: this.options,
      defaultValidatorOptions,
    };

    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.componentSubscribe = (
      this.componentRef.instance as HostModel
    ).events.subscribe((event: any) => {
      this.handleEvents(event);
    });

    if (this.options.type === 'inline') {
      this.elementRef.nativeElement.style.marginBottom =
        this.popupPosition['bottom'] + 'px';
    }
  }

  applyOptionsDefault(
    options: { popup: SimpleChange },
    defaultOption: ValidatorOptionsModel
  ): void {
    this.initOptions = Object.assign(
      {},
      this.initOptions || {},
      options,
      defaultOption
    );
  }

  handleEvents(event: any): void {
    if (event.type === 'shown') {
      this.events.emit({
        type: 'shown',
        position: this.popupPosition,
      });
    }
  }

  show(): void {
    if (!this.componentRef || this.isPopupDestroyed) {
      this.createPopup();
    } else if (!this.isPopupDestroyed) {
      this.showPopupElem();
    }
  }

  hide(): void {
    this.destroyPopup();
  }
}
