<div class="bg-base h-full block">
  <div class="block relative top-20">
    <div class="flex justify-center mb-6">
      <img src="../../assets/images/supercmms-white.png" class="logo" alt="Super Cmms" />
    </div>
    <div class="block text-center mb-2">
      <span class="font-bold uppercase">{{ title }}</span>
    </div>
    <app-reset-password [form]="form" [formControls]="formControls" [submitIcon]="submitIcon" [isSubmitting]="isLoading"
      (submitReset)="onResetPasswordSubmit()">
    </app-reset-password>
    <div *ngIf="errorMessage" class="mt-4 text-rejected text-center text-2xl leading-none">{{errorMessage}}</div>
  </div>
</div>
