import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MaxFileUploadSize } from 'src/app/util/common.constants';
import { MaterialIcon } from 'src/app/util/common.enum';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReportComponent {

  @Input() form: FormGroup;
  @Input() formControls: any;
  @Input() isSubmitting: boolean;
  @Input() errorMessage: string;

  @Output() submitReport: EventEmitter<any> = new EventEmitter<any>();

  materialIcon = MaterialIcon;
  startRecaptcha = '';
  selectedFiles: any[] = [];
  fileUploadLimit = 3;
  maxAllowedImageSize = 3;
  // maxAllowedVideoSize = 25;
  allowedImageFormats = ['png', 'jpeg', 'jpg', 'svg', 'tiff', 'bmp', 'gif', 'ico', 'webp'];
  allowedVideoFormats = ['avi', 'mp4', 'mpeg', 'ogv', 'webm', '3gp', 'mov'];

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: NzNotificationService,
  ) {}

  async submitFormData(): Promise<void> {
    if (this.startRecaptcha) {
      // const images = await this.uploadFiles();
      this.submitReport.emit(this.selectedFiles);
      this.startRecaptcha = '';
    }
  }

  async onInputChange(event: any) {
    if (this.selectedFiles.length > this.fileUploadLimit) {
      this.messageService.create('error',  'Error', `You can add a maximum of ${this.fileUploadLimit} images only`, { nzClass: 'error' });
      return;
    }
    const reader = new FileReader();
    const file: any = event.dataTransfer ? event.dataTransfer.files[0] as File : event.target.files[0] as File;

    const isFileValid = this.validateFile(file);
    if (!isFileValid) {
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageSrc = reader.result as string;
      file.imageSrc = imageSrc;
      this.selectedFiles.push(file);
      this.cdr.detectChanges();
    };
  }

  validateFile(file: File) {
    const fileSize = ((file.size / 1024)/1024);
    var ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (!this.allowedImageFormats.includes(ext) && !this.allowedVideoFormats.includes(ext)) {
      this.messageService.create('error',  'Invalid Format', `Only Images and Videos are allowed`, { nzClass: 'error' });
      return false;
    } else if (this.allowedImageFormats.includes(ext) && fileSize > this.maxAllowedImageSize) {
      this.messageService.create('error',  'Error', `Max size allowed is ${this.maxAllowedImageSize} MB.`, { nzClass: 'error' });
      return false;
    } else if (this.allowedVideoFormats.includes(ext) && fileSize > MaxFileUploadSize) {
      this.messageService.create('error',  'Error', `Max size allowed is ${MaxFileUploadSize} MB.`, { nzClass: 'error' });
      return false;
    }
    return true;
  }


}
