import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable()
export class AppResolver implements Resolve<any> {
  constructor(private appService: AppService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.appService.checkStatus();
  }
}
