import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { takeUntil } from 'rxjs';
import { TakeUntilDestroy } from 'src/app/shared/directives/take-until-destory.directive';
import { ValidTokenErrorMessage,  ServerErrorMessage } from 'src/app/util/common.constants';
import { MaterialIcon } from 'src/app/util/common.enum';
import { SignupFormModel } from '../../model/auth.model';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password-form-view',
  templateUrl: './reset-password-form-view.component.html',
  styleUrls: ['./reset-password-form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordFormViewComponent extends TakeUntilDestroy implements OnInit {

  form: FormGroup;
  formControls: any;
  submitIcon: string;
  cancelIcon: string;
  errorMessage: string;
  title = 'Reset Password';
  isLoading: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formService: AuthFormService,
    private authService: AuthService,
    private messageService: NzNotificationService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.submitIcon = MaterialIcon.ARROW_CIRCLE_RIGHT;
    this.cancelIcon = MaterialIcon.CLOSE_NORMAL;
    if (this.authService.isTokenValidated()) {
      const data = {
        email: this.activatedRoute.snapshot.params['emailId'],
        code: decodeURIComponent(this.activatedRoute.snapshot.params['code'])
      } as SignupFormModel;
      this.form = this.formService.buildResetPasswordForm();
      this.formControls = this.formService.getFormControls(this.form);
      this.formService.setResetPasswordValues(this.form, data);
    } else {
      // this.errorMessage = ValidTokenErrorMessage;
      this.messageService.create('error', 'Error', ValidTokenErrorMessage, { nzClass: 'error' });
    }
  }

  onResetPasswordSubmit() {
    this.isLoading = true;
    this.authService.resetPassword(this.form.getRawValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.isLoading = false;
        this.cdr.markForCheck();
        if (data.success) {
          this.messageService.create('success', 'Success', 'Reset password successful.', { nzClass: 'success' });
          this.router.navigate([`/login`]);
        } else {
          const message = data.message ? data.message : ServerErrorMessage;
          this.messageService.create('error', 'Error', message, { nzClass: 'error' });
        }
      })
  }

}
