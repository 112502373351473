import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { initializeStage, StatusModel, ValidatorOptionsModel } from '../model/password-validator.model';

@Injectable()
export class PasswordValidatorService {

  private value = new BehaviorSubject(initializeStage);
  updatedValue = this.value.asObservable();

  constructor() { }

  updateValue(data: StatusModel): void {
    this.value.next(data);
  }

  getUpdatedValue() {
    return this.value.asObservable();
  }

  deepMerge(
    target: ValidatorOptionsModel | any,
    source: ValidatorOptionsModel | any
  ): ValidatorOptionsModel {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object) {
        Object.assign(source[key], this.deepMerge(target[key], source[key]));
      }
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source);

    return target;
  }
}
