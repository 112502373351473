import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { AuthFormService } from './services/auth-form.service';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthFormViewModule } from './containers/auth-form-view/auth-form-view.module';
import { LoginFormViewModule } from './containers/login-form-view/login-form-view.module';
import { SignupFormViewModule } from './containers/signup-form-view/signup-form-view.module';
import { SignupInviteFormViewModule } from './containers/signup-invite-form-view/signup-invite-form-view.module';
import { ResetPasswordFormViewModule } from './containers/reset-password-form-view/reset-password-form-view.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthFormViewModule,
    AuthRoutingModule,
    LoginFormViewModule,
    SignupInviteFormViewModule,
    ResetPasswordFormViewModule,
    SignupFormViewModule
  ],
  providers: [AuthService, AuthFormService]
})
export class AuthModule { }
