<div class="flex justify-center">
  <form nz-form [formGroup]="form" class="reset-form">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.email">
        <input nzSize="large" nz-input [formControl]="formControls.email" placeholder="Email *"
          class="font-semibold text-center h-custom-input text-lg" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.password">
        <input nzSize="large" nz-input type="password" [formControl]="formControls.password" placeholder="Password *"
          class="font-semibold text-center h-custom-input text-lg" [PasswordValidator]="defaultOptions"
          (valid)="onValid($event)" />
      </nz-form-control>
    </nz-form-item>
    <div class="relative">
      <nz-form-item>
        <nz-form-control [nzValidateStatus]="formControls.confirmPassword">
          <input nzSize="large" nz-input type="password" [formControl]="formControls.confirmPassword"
            placeholder="Confirm Password *" class="font-semibold text-center h-custom-input text-lg"
            [PasswordValidator]="defaultOptions" (valid)="onValid($event)" />
        </nz-form-control>
      </nz-form-item>
      <div *ngIf="form.errors && form.errors['mismatch']" class="absolute -bottom-6 w-full text-center">
        <span class="text-rejected leading-none ml-2 confirm-password-text">Password is not matching</span>
      </div>
    </div>
  </form>
</div>
<div class="flex justify-center">
  <nz-spin nzSimple [nzSpinning]="isSubmitting" nzTip="Please wait..."></nz-spin>
  <button *ngIf="!isSubmitting" nz-button class="bg-inherit border-0" [disabled]="!form.valid || !isValid"
    (click)="onSubmitForm()">
    <span class="text-6xl cursor-pointer material-symbols-outlined leading-none save-icon"
      [ngClass]="{'selected': form.valid}">{{submitIcon}}</span>
  </button>
</div>