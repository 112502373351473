import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { CompanyModel } from './company.model';
import { showErrorMsg } from '../util/common.util';
import { ServerErrorMessage } from '../util/common.constants';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LocationService } from 'src/app/shared/components/location/services/location.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyComponent implements OnInit {

  @Input() companyData: CompanyModel[];

  companyControl: FormControl

  constructor(
    private router: Router,
    private appService: AppService,
    private locationService: LocationService,
    private messageService: NzNotificationService,
  ) { }

  ngOnInit(): void {
    this.companyControl = new FormControl('')
  }

  onCompanySelect(companyId: string) {
    // localStorage.removeItem('companyId');
    this.appService.sendSelectedCompany(companyId).subscribe(data => {
      if (data.success) {
        localStorage.setItem('AUTH', data.token);
        localStorage.setItem('companyId', companyId);
        this.appService.setUserPermissions();
        if (data.locations && data.locations.length) {
          this.locationService.setLocations(data.locations);
          this.router.navigate([`/home/assets/${data.locations[0].locationId}`]);
        } else {
          this.router.navigate(['/home']);
        }
      } else {
        showErrorMsg(this.messageService, data.message || ServerErrorMessage);
      }
    });
  }

  trackByCompanyId(index: number, company: CompanyModel) {
    return company.companyId;
  }

}
