import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFormViewComponent } from './report-form-view.component';
import { ReportFormModule } from '../../components/report/report.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CompanyModule } from 'src/app/company/company.module';
import { FormsModule } from '@angular/forms';
import { ImageFileRepositoryService } from 'src/app/shared/components/image-file-repository/services/image-file-repository.service';

@NgModule({
  declarations: [
    ReportFormViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReportFormModule,
    NzNotificationModule,
    NzButtonModule,
    NzSpinModule,
    CompanyModule
  ],
  providers: [ImageFileRepositoryService],
  exports: [ReportFormViewComponent]
})
export class ReportFormViewModule { }
