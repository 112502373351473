import { NzNotificationService } from "ng-zorro-antd/notification";
import { ServerErrorMessage } from "./common.constants";
import { ManufacturerModel, TableFilterDataModel, TextValueModel, UserModel, VendorModel } from "./common.model";

export const isEmpty = (obj: any): boolean => {
  return obj !== undefined && obj !== null;
}

export const textValueTrackBy = (index: number, item: TextValueModel) => {
  return item.value;
}

export const trackByManufacturerId = (index: number, manufacturer: ManufacturerModel) => {
  return manufacturer.manufacturerId;
}

export const trackByVendorId = (index: number, vendor: VendorModel) => {
  return vendor.vendorId;
}

export const trackByUserId = (index: number, user: UserModel) => {
  return user.uid;
}

export const getFileNameExt = (fileName: string) => {
  if (fileName) {
    const nameArray = fileName.split('.');
    return { name: nameArray[0], ext: nameArray[1] };
  }
  return { name: '', ext: '' };
}

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
}

export const getDifference = (a: any, b: any) => Object.fromEntries(Object.entries(b).filter(([key, val]) => key in a && a[key] !== val));

export const filtersToPayload = (filterData: TableFilterDataModel[]) => {
  return filterData.reduce((acc, curr) => {
    if (curr.value && curr.value.length) {
      return { ...acc, [curr.key]: curr.value.toString() }
    }
    return { ...acc };
  },{})
}

export const formatDueDate = (dueDate: any) => {
  return dueDate.getFullYear() + '-' + (dueDate.getMonth() + 1).toString().padStart(2, '0') + '-' + dueDate.getDate().toString().padStart(2, '0');
}

export const getPercentage = (fileSize: number, fileType: string) => {
  const percentage = fileType.includes('png') ? 50 : 80;
  return Math.ceil(((1024 / fileSize) * 1024) * percentage);
}

export const showErrorMsg = (messageService: NzNotificationService, message: string) => {
  messageService.create('error',  'Error', message || ServerErrorMessage, { nzClass: 'error' });
}

export const showSuccessMsg = (messageService: NzNotificationService, message: string) => {
  messageService.create('success',  'Success', message, { nzClass: 'success' });
}
