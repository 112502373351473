export interface ValidatorOptionsModel {
  placement?: 'top' | 'right' | 'bottom' | 'left';
  'z-index'?: number;
  'animation-duration'?: number;
  'custom-class'?: string;
  shadow?: boolean;
  theme?: 'basic' | 'pro';
  type?: 'inline' | 'popup';
  offset?: number;
  width?: number;
  'max-width'?: number;
  position?: PositionModel;
  rules?: RulesModel;
  heading?: string;
  successMessage?: string;
  template?: string;
}

export interface RulesModel {
  password?: false | PasswordModel;
  'include-symbol'?: boolean;
  'include-number'?: boolean;
  'include-lowercase-characters'?: boolean;
  'include-uppercase-characters'?: boolean;
}

export interface PositionModel {
  top: number;
  left: number;
}

export interface PasswordModel {
  type?: 'number' | 'range' | 'min';
  length?: number;
  min?: number;
  max?: number;
}

export interface ElementPositionModel {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

export interface StatusModel {
  password: boolean;
  'include-symbol': boolean;
  'include-number': boolean;
  'include-lowercase-characters': boolean;
  'include-uppercase-characters': boolean;
}

export interface HostModel {
  data: any;
  show: boolean;
  close: boolean;
  events: any;
}

export const defaultValidatorOptions: ValidatorOptionsModel = {
  template: 'inline',
  placement: 'right',
  'z-index': 0,
  'custom-class': '',
  shadow: true,
  theme: 'pro',
  type: 'popup',
  offset: 8,
  heading: 'Password Policy',
  successMessage: 'Awesome! Password requirement fulfilled.',
  rules: {
    password: {
      type: 'min',
      length: 6,
      min: 7,
      max: 10
    },
    'include-symbol': true,
    'include-number': true,
    'include-lowercase-characters': true,
    'include-uppercase-characters': false
  },
};

export const initializeStage: StatusModel = {
  password: false,
  'include-symbol': false,
  'include-number': false,
  'include-lowercase-characters': false,
  'include-uppercase-characters': false,
};
