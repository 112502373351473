<div class="bg-base h-full block">
  <div class="block relative top-20">
    <div class="flex justify-center mb-6">
      <img src="../../assets/images/supercmms-white.png" class="logo" alt="Super Cmms" />
    </div>
    <div class="block text-center mb-2" *ngIf="title">
      <span class="font-bold uppercase text-lg">{{ title }}</span>
    </div>
    <app-login *ngIf="!showCompany && !showCreateCompany" [form]="form" [formControls]="formControls"
      [questionIcon]="questionIcon" [submitIcon]="submitIcon" [isSubmitting]="isLoading" [isLogin]="isLogin"
      [cancelIcon]="cancelIcon" [errorMessage]="errorMessage" [showCode]="showCode" [codeForm]="codeForm"
      [codeFormControls]="codeFormControls" (gotoLogin)="navigateToLogin()" (navigateToForgot)="navigateToForgot()"
      (navigateToInvite)="navigateToInvitePage()" (submitLogin)="onSubmit()" (validateCode)="validateCode()">
    </app-login>
    <app-company *ngIf="showCompany && !showCreateCompany" [companyData]="companyData"></app-company>
    <div *ngIf="showCreateCompany && !showCompany" class="flex justify-center">
      <nz-spin [nzSpinning]="createCompanyLoading">
        <div class="">
          <div class="text-center">
            You are not linked to any company. <br>
            Create your own company on SuperCMMS?
          </div>
          <div class="mt-4 w-full">
            <input nzSize="large" [(ngModel)]="companyName" nz-input type="text" placeholder="Enter company name"
              class="font-semibold text-center h-[40px]  w-full" />
          </div>
          <div class="mt-4 flex justify-between">
            <button nz-button class="mr-4 border" [ngClass]="{'bg-completed border-completed':companyName}"
              [disabled]="!companyName">
              <span class=" font-bold" [ngClass]="{'text-white':companyName}"
                (click)="createCompany()">Create</span></button>
            <button nz-button class="bg-rejected border-rejected border"><span class="text-white font-bold"
                (click)="cancelCreateCompany()">Logout</span></button>
          </div>

        </div>
      </nz-spin>
    </div>
  </div>
</div>