import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthFormViewComponent } from './auth-form-view.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [
    AuthFormViewComponent
  ],
  imports: [
    CommonModule,
    NzNotificationModule,
    NzButtonModule,
    NzSpinModule
  ],
  exports:[AuthFormViewComponent]
})
export class AuthFormViewModule { }
