export enum DOC_ORIENTATION {
  Up = 1,                     //Horizontal (normal)
  Down = 3,                   //Rotate 180
  Right = 6,                  //Rotate 90 CW
  Left = 8,                   //Rotate 270 CW
  UpMirrored = 2,             //Mirror horizontal
  DownMirrored = 4,           //Mirror vertical
  LeftMirrored = 5,           //Mirror horizontal and rotate 270 CW
  RightMirrored = 7,          //Mirror horizontal and rotate 90 CW
  Default = 0,
  NotJpeg = -1,
  NotDefined = -2
}

export interface UploadResponse {
  image: string;
  orientation: DOC_ORIENTATION;
  fileName: string;
}
export class ImageCompress {
  static getOrientation = (file: File): Promise<DOC_ORIENTATION> =>
    new Promise<DOC_ORIENTATION>((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => {
          const view = new DataView(reader.result as ArrayBuffer);
          if (!view.byteLength) {
            return resolve(DOC_ORIENTATION.NotDefined);
          }
          if (view.getUint16(0, false) !== 0xffd8) {
            return resolve(DOC_ORIENTATION.NotDefined);
          }
          const length = view.byteLength;
          let offset = 2;
          while (offset < length) {
            const marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xffe1) {
              if (view.getUint32((offset += 2), false) !== 0x45786966) {
                return resolve(DOC_ORIENTATION.NotJpeg);
              }
              const little = view.getUint16((offset += 6), false) === 0x4949;
              offset += view.getUint32(offset + 4, little);
              const tags = view.getUint16(offset, little);
              offset += 2;
              for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + i * 12, little) === 0x0112) {
                  return resolve(view.getUint16(offset + i * 12 + 8, little));
                }
              }
            } else if ((marker & 0xff00) !== 0xff00) {
              break;
            } else {
              offset += view.getUint16(offset, false);
            }
          }
          return resolve(DOC_ORIENTATION.NotJpeg);
        };
        reader.readAsArrayBuffer(file);
      } catch (e) {
        return reject(DOC_ORIENTATION.Default);
      }
    });

  static fileToDataURL = (file: File): Promise<{ dataUrl: string; fileName: string }> => {
    return new Promise<{ dataUrl: string; fileName: string }>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        //myReader.onloadend = (progressEvent: ProgressEvent<FileReader>)
        resolve({ dataUrl: e.target.result, fileName: file.name });
      };
      try {
        reader.readAsDataURL(file);
      } catch (e) {
        reject(`probably no file have been selected: ${e}`);
      }
    });
  };


  static compress = (
    file: File,
    quality: number = 50,
    ratio: number = 50,
    maxwidth: number = 0,
    maxheight: number = 0,
    imageDataUrlSource: string = ''
  ): Promise<string> =>
    new Promise(async function (resolve, reject) {
      quality = quality / 100;
      ratio = ratio / 100;
      const sourceImage = new Image();
      const imageUrl = await ImageCompress.fileToDataURL(file);
      const orientation = await ImageCompress.getOrientation(file);
      imageDataUrlSource = imageUrl.dataUrl;
      // important for safari: we need to wait for onload event
      sourceImage.onload = () => {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        if (!ctx) {
          return reject(`No canvas context available`);
        }

        let w = sourceImage.naturalWidth;
        let h = sourceImage.naturalHeight;

        if (!CSS.supports('image-orientation', 'from-image')) {
          if (orientation === DOC_ORIENTATION.Right || orientation === DOC_ORIENTATION.Left) {
            const t = w;
            w = h;
            h = t;
          }
        }

        let xratio = maxwidth ? maxwidth / w : 1;
        let yratio = maxheight ? maxheight / h : 1;
        ratio = Math.min(ratio, xratio, yratio);
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        const TO_RADIANS = Math.PI / 180;

        if (CSS.supports('image-orientation', 'from-image') || orientation === DOC_ORIENTATION.Up) {
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        } else if (orientation === DOC_ORIENTATION.Right) {
          ctx.save();
          ctx.rotate(90 * TO_RADIANS);
          ctx.translate(0, -canvas.width);
          ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
          ctx.restore();
        } else if (orientation === DOC_ORIENTATION.Left) {
          ctx.save();
          ctx.rotate(-90 * TO_RADIANS);
          ctx.translate(-canvas.width, 0);
          ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
          ctx.restore();
        } else if (orientation === DOC_ORIENTATION.Down) {
          ctx.save();
          ctx.rotate(180 * TO_RADIANS);
          ctx.translate(-canvas.width, -canvas.height);
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
          ctx.restore();
        } else {
          // no orientation value found - same as default UP
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        }
        const mime = imageDataUrlSource.substr(5, imageDataUrlSource.split(';')[0].length - 5);
        // TODO test on mime
        const result = canvas.toDataURL(mime, quality);
        resolve(result);
      };
      sourceImage.onerror = e => reject(e);
      sourceImage.src = imageDataUrlSource;
    });

  static byteCount = (imgString: string): number => encodeURI(imgString).split(/%..|./).length - 1;
  static base64ToFile = (base64: any, mime = "image/jpeg", fileName: string) => {
    const byteString = atob(base64);
    const content = [];
    for (let i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([new Uint8Array(content)], { type: mime });
    return new File([blob], fileName, { type: mime });
  };
}
