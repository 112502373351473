import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportFormModel  } from '../model/report.model';

@Injectable()
export class ReportFormService {

  constructor(private fb: FormBuilder) { }

  buildReportForm(): FormGroup {
    return this.fb.group({
      title: [null, [Validators.required]],
      description: [null],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.minLength(10)]]
    })
  }

  getFormControls(form: FormGroup): { [key in keyof (ReportFormModel)]: FormControl } {
    return form.controls as { [key in keyof (ReportFormModel)]: FormControl };
  }

}
