import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { ApiUrls } from 'src/app/util/common.enum';
import { DataResponseModel } from 'src/app/util/common.model';
import { environment } from 'src/environments/environment';
import { LoginFormModel, ResetFormModel, AuthVerifyModel, SignupFormModel, AuthCodeModel } from '../model/auth.model';
import { LoginResponseModel } from '../model/auth.model';

@Injectable()
export class AuthService {

  private isValidToken: boolean;
  private companyName: string;
  private companyId: string;
  private name: string;
  private fbToken: string;

  constructor(private http: HttpClient, private auth: AngularFireAuth) { }

  login(data: LoginFormModel): Observable<LoginResponseModel> {
    return this.http.post<LoginResponseModel>(`${environment.baseUrl}${ApiUrls.Login}`, data);
  }
  createCompany(data:any): Observable<LoginResponseModel> {
    return this.http.post<LoginResponseModel>(`${environment.baseUrl}${ApiUrls.createCompany}`, data);
  }
  
  forgotPassword(data: LoginFormModel): Observable<DataResponseModel<any>> {
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${ApiUrls.ForgotPassword}`, data);
  }

  signup(data: SignupFormModel): Observable<DataResponseModel<any>> {
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${ApiUrls.Signup}`, data);
  }

  validateCode(code: AuthCodeModel): Observable<DataResponseModel<any>> {
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${ApiUrls.ValidateCode}`, code);
  }

  verify(data: AuthVerifyModel, type: string): Observable<DataResponseModel<any>> {
    let verifyUrl;
    if (type === 'reset-password') {
      verifyUrl = ApiUrls.ResetVerify
    } else if (type === 'link-user') {
      verifyUrl = ApiUrls.InviteVerify;
    } else {
      verifyUrl = ApiUrls.SignupVerify;
    }
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${verifyUrl}`, data);
  }

  invite(email: string): Observable<DataResponseModel<any>> {
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${ApiUrls.Invite}`, { email });
  }

  resetPassword(data: ResetFormModel): Observable<any> {
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${ApiUrls.ResetPassword}`, data);
  }

  linkUser(data: any): Observable<any> {
    return this.http.post<DataResponseModel<any>>(`${environment.baseUrl}${ApiUrls.LinkUser}`, data);
  }

  setTokenStatus(isValidToken: boolean) {
    this.isValidToken = isValidToken;
  }

  isTokenValidated() {
    return this.isValidToken;
  }

  setCompanyName(name: string) {
    this.companyName = name;
  }

  getCompanyName() {
    return this.companyName;
  }

  setCompanyId(id: string) {
    this.companyId = id;
  }

  getCompanyId() {
    return this.companyId;
  }

  setName(name: string) {
    this.name = name;
  }

  getName() {
    return this.name;
  }

  setFbToken(fbToken: string) {
    this.fbToken = fbToken;
    this.authenticateFb();
    localStorage.setItem('fbAuth', fbToken);
  }

  async authenticateFb() {
    const user = await this.isFbLoggedIn();
    if (user) {
      // do something
    } else {
      try {
        const fbToken = this.fbToken || (localStorage.getItem('fbAuth') || '');
        await this.auth.setPersistence('session');
        await this.auth.signInWithCustomToken(fbToken);
      } catch (err: any) {
        throw new Error(err)
      }
    }
  }

  isFbLoggedIn() {
    return this.auth.authState.pipe(first()).toPromise()
  }

  fbLogout() {
    this.auth.signOut();
    localStorage.clear();
  }

}
