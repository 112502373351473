import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetDetailsModel } from 'src/app/home/assets/models/assets.model';

import { ApiUrls } from 'src/app/util/common.enum';
import { DataResponseModel } from 'src/app/util/common.model';
import { environment } from 'src/environments/environment';
import { ReportFormModel } from '../model/report.model';
import { ReportResponseModel } from '../model/report.model';

@Injectable()
export class ReportService {

  constructor(private http: HttpClient) { }

  report(data: ReportFormModel): Observable<ReportResponseModel> {
    return this.http.post<ReportResponseModel>(`${environment.baseUrl}${ApiUrls.Report}`, data);
  }

  getAssetDetails(assetId: string): Observable<DataResponseModel<AssetDetailsModel>> {
    return this.http.get<DataResponseModel<AssetDetailsModel>>(`${environment.baseUrl}${ApiUrls.GetAssetPublicDetails}?assetId=${assetId}`);
  }

}
