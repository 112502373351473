import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthFormViewComponent } from './containers/auth-form-view/auth-form-view.component';
import { LoginFormViewComponent } from './containers/login-form-view/login-form-view.component';
import { ResetPasswordFormViewComponent } from './containers/reset-password-form-view/reset-password-form-view.component';
import { SignupFormViewComponent } from './containers/signup-form-view/signup-form-view.component';
import { SignupInviteFormViewComponent } from './containers/signup-invite-form-view/signup-invite-form-view.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginFormViewComponent
  },
  {
    path: 'signup/:emailId/:code',
    component: SignupFormViewComponent
  },
  {
    path:'reset-password/:emailId/:code',
    component: ResetPasswordFormViewComponent
  },
  {
    path:'signup-invite',
    component: SignupInviteFormViewComponent
  },
  {
    path:'verify/:emailId/:code',
    component: AuthFormViewComponent
  },
  {
    path:'verify/:emailId/:code/:type',
    component: AuthFormViewComponent
  },
  {
    path:'link-user/:emailId/:code',
    component: AuthFormViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
