import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CompanyModel } from 'src/app/company/company.model';
import { TakeUntilDestroy } from 'src/app/shared/directives/take-until-destory.directive';
import { ServerErrorMessage } from 'src/app/util/common.constants';
import { MaterialIcon } from 'src/app/util/common.enum';
import { AuthCodeModel, LoginResponseModel } from '../../model/auth.model';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthService } from '../../services/auth.service';
import { showErrorMsg } from 'src/app/util/common.util';
import { LocationService } from 'src/app/shared/components/location/services/location.service';

@Component({
  selector: 'app-login-form-view',
  templateUrl: './login-form-view.component.html',
  styleUrls: ['./login-form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormViewComponent extends TakeUntilDestroy implements OnInit {

  form: FormGroup;
  formControls: any;
  questionIcon: string;
  submitIcon: string;
  cancelIcon: string;
  isLogin: boolean;
  isLoading: boolean;
  title: string;
  showCompany: boolean;
  companyData: CompanyModel[];
  errorMessage: string;
  showCode: boolean;
  codeForm: FormGroup;
  codeFormControls: { [key in keyof AuthCodeModel]: FormControl };
  showCreateCompany = false;
  companyName = '';
  userId:string;
  createCompanyLoading = false;

  constructor(
    private router: Router,
    private formService: AuthFormService,
    private authService: AuthService,
    private locationService: LocationService,
    private cdr: ChangeDetectorRef,
    private messageService: NzNotificationService,
    private appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {
    //this.showCreateCompany = true;
    this.questionIcon = MaterialIcon.QUESTION;
    this.submitIcon = MaterialIcon.ARROW_CIRCLE_RIGHT;
    this.cancelIcon = MaterialIcon.CLOSE_NORMAL;
    this.form = this.formService.buildLoginForm();
    this.formControls = this.formService.getFormControls(this.form);
    this.codeForm = this.formService.buildCodeForm();
    this.codeFormControls = this.formService.getCodeFormControls(this.codeForm);
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    if (baseUrl.includes('staging')) {
      this.showCode = true;
      this.title = 'Code';
      this.isLogin = false;
    } else {
      this.title = 'Login';
      this.isLogin = true;
    }
  }

  navigateToLogin() {
    this.isLogin = true;
    this.title = 'Login';
    this.formService.makeFieldRequired(this.form, 'password');
  }

  navigateToForgot() {
    this.isLogin = false;
    this.title = 'Forgot Password';
    this.formService.makeFieldOptional(this.form, 'password');
  }

  navigateToInvitePage() {
    this.router.navigate(['/signup-invite']);
  }

  onSubmit() {
    if (this.isLogin) {
      this.onLoginSubmit();
    } else {
      this.onForgotSubmit();
    }
  }

  onForgotSubmit() {
    this.errorMessage = '';
    this.isLoading = true;
    this.authService.forgotPassword(this.form.getRawValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.isLoading = false;
          if (data.success) {
            this.messageService.create('success', 'Success', data.message || `Password reset link successfully sent to your email id.`, { nzClass: 'success' });
            localStorage.clear();
            this.router.navigate([`/login`]);
          } else {
            this.messageService.create('error', 'Error', data.message || ServerErrorMessage, { nzClass: 'error' });
          }
          this.cdr.markForCheck();
        },
        error: (e) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  onLoginSubmit() {
    this.errorMessage = '';
    this.isLoading = true;
    this.authService.login(this.form.getRawValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: LoginResponseModel) => {
          this.isLoading = false;
          this.cdr.markForCheck();
          if (data.success) {
            this.authService.setFbToken(data.fbToken)
            this.appService.setUserInfo(data.user);
            localStorage.setItem('AUTH', data.token);
            if (data.user?.companies && data.user.companies.length) {
              if (data.user.companies.length > 1) {
                this.title = '';
                this.showCompany = true;
                this.companyData = [...data.user.companies];
              } else {
                data.user.companies.forEach((company: CompanyModel) => {
                  localStorage.setItem('companyId', company.companyId);
                  this.appService.setUserPermissions();
                });
                if (data.locations && data.locations.length) {
                  this.locationService.setLocations(data.locations);
                  this.router.navigate([`/home/assets/${data.locations[0].locationId}`]);
                } else {
                  this.router.navigate(['/home']);
                }
              }
            } else {
              this.showCreateCompany = true;
              this.userId = this.form?.controls['email']?.value;
              // this.router.navigate(['/home']);
            }
          } else if (!data.success && !data.exists) {
            showErrorMsg(this.messageService, 'Wrong email or password');
            this.errorMessage = '';
          } else {
            showErrorMsg(this.messageService, data.message || ServerErrorMessage);
          }
        },
        error: (e) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  validateCode() {
    this.errorMessage = '';
    this.isLoading = true;
    this.authService.validateCode(this.codeForm.getRawValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.isLoading = false;
          if (data.success) {
            this.showCode = false;
            this.isLogin = true;
            this.title = 'Login';
            this.codeForm.reset();
          }
          this.cdr.markForCheck();
        },
        error: (e) => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      })
  }
  createCompany(){
    this.errorMessage = '';
    this.createCompanyLoading = true;
    this.authService.createCompany({companyName:this.companyName,userId:this.userId})
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.success) {
            this.authService.setFbToken(data.fbToken)
            this.appService.setUserInfo(data.user);
            localStorage.setItem('AUTH', data.token);
            if (data.user?.companies && data.user.companies.length) {
              if (data.user.companies.length > 1) {
                this.title = '';
                this.showCompany = true;
                this.companyData = [...data.user.companies];
              } else {
                data.user.companies.forEach((company: CompanyModel) => {
                  localStorage.setItem('companyId', company.companyId);
                  this.appService.setUserPermissions();
                });
                this.cdr.markForCheck();
                this.router.navigate(['/home']);
              }
            }
          } else {
            this.createCompanyLoading = false;
          }
          this.cdr.markForCheck();
        },
        error: (e) => {
          this.createCompanyLoading = false;
          this.cdr.markForCheck();
        }
      })
  }
  cancelCreateCompany(){
    this.showCreateCompany = false
  }

}
