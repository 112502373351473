import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupInviteFormViewComponent } from './signup-invite-form-view.component';
import { SignupInviteModule } from '../../components/signup-invite/signup-invite.module';

@NgModule({
  declarations: [
    SignupInviteFormViewComponent
  ],
  imports: [
    CommonModule,
    SignupInviteModule
  ],
  exports: [SignupInviteFormViewComponent]
})
export class SignupInviteFormViewModule { }
