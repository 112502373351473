import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { defaultValidatorOptions, ElementPositionModel, RulesModel, StatusModel, ValidatorOptionsModel } from './model/password-validator.model';
import { PasswordValidatorService } from './services/password-validator.service';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'popup'}
})
export class PasswordValidatorComponent implements OnInit {

  heading: string;
  successMessage: string;
  passwordStatus = {
    password: false,
    'include-symbol': false,
    'include-number': false,
    'include-lowercase-characters': false,
    'include-uppercase-characters': false,
  } as any;
  isSecure = false;
  Show = false;
  events = new EventEmitter();
  isPasswordSecure = new EventEmitter();
  passwordOptions: ValidatorOptionsModel | any = { ...defaultValidatorOptions };

  @Input() data: any;

  @HostBinding('style.top') hostStyleTop: string;
  @HostBinding('style.left') hostStyleLeft: string;
  @HostBinding('style.z-index') hostStyleZIndex: number;
  @HostBinding('style.transition') hostStyleTransition: string;
  @HostBinding('style.width') hostStyleWidth: string;
  @HostBinding('style.max-width') hostStyleMaxWidth: string;
  @HostBinding('style.pointer-events') hostStylePointerEvents: string;
  @HostBinding('class.popup-show') hostClassShow: boolean;
  @HostBinding('class.popup-shadow') hostClassShadow: boolean;

  @HostListener('transitionend', [''])
  transitionEnd(): void {
    if (this.show) {
      this.events.emit({
        type: 'shown',
      });
    }
  }

  @Input() set show(value: boolean) {
    if (value) {
      this.setPosition();
    }
    this.Show = this.hostClassShow = value;
  }

  get show(): boolean {
    return this.Show;
  }

  get placement(): string {
    return this.data.options.placement;
  }

  get element() {
    return this.data.element;
  }

  get elementPosition(): ElementPositionModel {
    return this.data.elementPosition;
  }

  get options(): ValidatorOptionsModel {
    return this.data.options;
  }

  get popupOffset(): number {
    switch (this.data.options.offset) {
      case '':
        return defaultValidatorOptions.offset as any;

      case '0':
        return +this.data.options.offset;

      default:
        return +this.data.options.offset;
    }
  }

  get rules(): RulesModel {
    return {
      ...this.data.defaultValidatorOptions.rules,
      ...this.data.options.rules,
    };
  }

  get defaultValidatorOptions(): ValidatorOptionsModel {
    return this.data.defaultValidatorOptions;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private service: PasswordValidatorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setCustomClass();
    this.setStyles();
    this.setTheme();
    this.setCustomText();

    this.service.getUpdatedValue().subscribe((data: StatusModel) => {
      this.passwordStatus = { ...this.passwordStatus, ...data };
      for (const propName in this.passwordOptions.rules) {
        if (!this.passwordOptions.rules[propName]) {
          delete this.passwordStatus[propName];
        }
      }
      this.isSecure = Object.values(this.passwordStatus).every(
        (value) => value
      );
      this.isPasswordSecure.emit(this.isSecure);
      this.cdr.markForCheck();
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['data'] && changes['data'].currentValue) {
      this.data = changes['data'].currentValue;
    }
  }

  setPosition(): void {
    if (this.setHostStyle(this.placement)) {
      this.setPlacementClass(this.placement);

      return;
    } else {
      // Is popup outside the visible area
      const placements = ['top', 'right', 'bottom', 'left'];
      let isPlacementSet;

      for (const placement of placements) {
        if (this.setHostStyle(placement)) {
          this.setPlacementClass(placement);
          isPlacementSet = true;

          return;
        }
      }

      // Set original placement
      if (!isPlacementSet) {
        this.setHostStyle(this.placement);
        this.setPlacementClass(this.placement);
      }
    }
  }

  setPlacementClass(placement: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'popup-' + placement);
  }

  setHostStyle(placement: string): boolean {
    const isSvg = this.element instanceof SVGElement;
    const popup = this.elementRef.nativeElement;
    const isCustomPosition = !this.elementPosition.right;

    let elementHeight = isSvg
      ? this.element.getBoundingClientRect().height
      : this.element.offsetHeight;
    let elementWidth = isSvg
      ? this.element.getBoundingClientRect().width
      : this.element.offsetWidth;
    const popupHeight = popup.clientHeight;
    const popupWidth = popup.clientWidth;
    const scrollY = window.pageYOffset;

    if (isCustomPosition) {
      elementHeight = 0;
      elementWidth = 0;
    }

    let topStyle;
    let leftStyle;

    switch (placement) {
      case 'top':
        topStyle =
          this.elementPosition.top + scrollY - (popupHeight + this.popupOffset);
        leftStyle = this.elementPosition.left;

        break;

      case 'bottom':
        topStyle =
          this.elementPosition.top + scrollY + elementHeight + this.popupOffset;
        leftStyle = this.elementPosition.left;

        break;
      case 'left':
        leftStyle = this.elementPosition.left - popupWidth - this.popupOffset;
        topStyle = this.elementPosition.top + scrollY;

        break;

      case 'right':
        leftStyle = this.elementPosition.left + elementWidth + this.popupOffset;
        topStyle = this.elementPosition.top + scrollY;
    }

    this.hostStyleTop = topStyle + 'px';
    this.hostStyleLeft = leftStyle + 'px';

    return true;
  }

  setZIndex(): void {
    if (this.options['z-index'] !== 0) {
      this.hostStyleZIndex = this.options['z-index'] as any;
    }
  }

  setCustomClass(): void {
    if (this.options['custom-class']) {
      this.options['custom-class'].split(' ').forEach((className: string) => {
        this.renderer.addClass(this.elementRef.nativeElement, className);
      });
    }
  }

  setTheme(): void {
    if (this.options['theme']) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'popup-' + this.options['theme']
      );
    }
  }

  setCustomText(): void {
    if (this.options['heading']) {
      this.heading = this.options['heading'];
    }

    if (this.options['successMessage']) {
      this.successMessage = this.options['successMessage'];
    }
  }

  setAnimationDuration(): void {
    this.hostStyleTransition =
      'opacity ' + this.options['animation-duration'] + 'ms';
  }

  setStyles(): void {
    this.setZIndex();
    this.setAnimationDuration();
    if (this.options.type !== 'inline') {
      this.hostClassShadow = this.options['shadow'] as any;
    }
    this.hostStyleMaxWidth = this.options['max-width'] + 'px';
    this.hostStyleWidth = this.options['width']
      ? this.options['width'] + 'px'
      : '';
  }

}
