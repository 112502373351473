import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { defaultValidatorOptions } from 'src/app/shared/components/password-validator/model/password-validator.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent {

  @Input() form: FormGroup;
  @Input() formControls: any;
  @Input() submitIcon: string;
  @Input() isSubmitting: boolean;

  @Output() submitReset: EventEmitter<void> = new EventEmitter<void>();

  strength: string;
  defaultOptions = defaultValidatorOptions;
  isValid: boolean;

  constructor() {}

  onSubmitForm(): void {
    this.submitReset.emit();
  }

  onValid(isValid: boolean) {
    this.isValid = isValid;
  }

}
