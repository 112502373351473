import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupFormViewComponent } from './signup-form-view.component';
import { SignupModule } from '../../components/signup/signup.module';

@NgModule({
  declarations: [
    SignupFormViewComponent
  ],
  imports: [
    CommonModule,
    SignupModule
  ],
  exports: [SignupFormViewComponent]
})
export class SignupFormViewModule { }
