import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyComponent } from './company.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { LocationService } from '../shared/components/location/services/location.service';

@NgModule({
  declarations: [
    CompanyComponent
  ],
  imports: [
    CommonModule,
    NzRadioModule,
    ReactiveFormsModule
  ],
  exports: [CompanyComponent],
  providers: [LocationService]
})
export class CompanyModule { }
