import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isEmpty } from '../util/common.util';

export const customDecimal = (
  numberLength: number,
  decimals: number
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isEmpty(numberLength) && !isEmpty(decimals)) {
      return null;
    }

    if (isEmpty(Validators.required(control))) {
      return null;
    }

    const regexp = new RegExp(
      `^-?\\d{0,${numberLength}}(\\.\\d{0,${decimals}})?$`
    );
    const v = control.value;
    return regexp.test(v)
      ? null
      : { maxlengthdecimal: { numberLength, decimals } };
  };
};

export const checkIfValuesMatching = (controlName: string, anotherControlName: string): ValidatorFn => {
  return (group: AbstractControl): ValidationErrors | null => {
    const controlValue = group.get(controlName)?.value;
    const anotherControlValue = group.get(anotherControlName)?.value;
    return controlValue === anotherControlValue ? null : { mismatch: true }
  }
};
