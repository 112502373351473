import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormViewComponent } from './login-form-view.component';
import { LoginModule } from '../../components/login/login.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CompanyModule } from 'src/app/company/company.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LoginFormViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoginModule,
    NzNotificationModule,
    NzButtonModule,
    NzSpinModule,
    CompanyModule
  ],
  exports: [LoginFormViewComponent]
})
export class LoginFormViewModule { }
