import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthCodeModel } from '../../model/auth.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

  @Input() form: FormGroup;
  @Input() formControls: any;
  @Input() submitIcon: string;
  @Input() questionIcon: string;
  @Input() cancelIcon: string;
  @Input() isSubmitting: boolean;
  @Input() isLogin: boolean;
  @Input() errorMessage: string;
  @Input() showCode: boolean;
  @Input() codeForm: FormGroup;
  @Input() codeFormControls: { [key in keyof AuthCodeModel]: FormControl };

  @Output() submitLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToForgot: EventEmitter<void> = new EventEmitter<void>();
  @Output() gotoLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToInvite: EventEmitter<void> = new EventEmitter<void>();
  @Output() validateCode: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  submitFormData(): void {
    this.submitLogin.emit()
  }

  verifyCode() {
    this.validateCode.emit();
  }

  onForgot(): void {
    this.navigateToForgot.emit();
  }

  onGotoLogin(): void {
    this.gotoLogin.emit();
  }

  onSignUp(): void {
    this.navigateToInvite.emit();
  }

}
