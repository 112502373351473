import { Directive, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Directive()
export class TakeUntilDestroy implements OnDestroy {
  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
