<div class="bg-base h-full block">
  <div class="block relative top-1/4">
    <div class="block text-center mb-2" *ngIf="title">
      <span class="font-bold uppercase">{{ title }}</span>
    </div>
    <div *ngIf="errorMessage" class="mt-4 text-rejected text-center text-2xl leading-none">{{errorMessage}}</div>
    <div class="flex flex-col items-center" *ngIf="showLinkUser">
      <nz-spin [nzSpinning]="isSubmitted" class="h-full">
        <span class="font-bold text-xl leading-none">You have been invited to join {{companyName}} on SuperCMMS</span>
        <div class="mt-4 text-center">
          <button nz-button class="mr-4 bg-completed border-completed border"><span
              class="text-white font-bold" (click)="linkUser(true)">Accept</span></button>
          <button nz-button class="bg-rejected border-rejected border"><span
              class="text-white font-bold" (click)="linkUser(false)">Reject</span></button>
        </div>
      </nz-spin>
    </div>
  </div>
</div>
