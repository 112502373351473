import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { takeUntil } from 'rxjs';
import { TakeUntilDestroy } from 'src/app/shared/directives/take-until-destory.directive';
import {  ServerErrorMessage, ValidTokenErrorMessage } from 'src/app/util/common.constants';
import { MaterialIcon } from 'src/app/util/common.enum';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-form-view',
  templateUrl: './auth-form-view.component.html',
  styleUrls: ['./auth-form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthFormViewComponent extends TakeUntilDestroy implements OnInit {

  @ViewChild('container') container: any;

  form: FormGroup;
  formControls: any;
  submitIcon: string;
  questionIcon: string;
  cancelIcon: string;
  isSubmitted: boolean;
  routeName: string;
  showLinkUser: boolean;
  title: string;
  pageRouteName: string;
  message: string;
  errorMessage: string;
  companyName: string;
  email: string;
  code: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private messageService: NzNotificationService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    /* if (localStorage.getItem('AUTH')) {
      localStorage.clear();
        setTimeout(() => {
          this.inIt();
        }, 1000);
    } else{
      this.inIt()    
    } */
    this.inIt()  
  }
  inIt(){
    this.isSubmitted = false;
    this.questionIcon = MaterialIcon.QUESTION;
    this.submitIcon = MaterialIcon.ARROW_CIRCLE_RIGHT;
    this.cancelIcon = MaterialIcon.CLOSE_NORMAL;
    const urlArray = this.router.url.split('/');
    this.pageRouteName = urlArray[1];
    this.preparePageData();
  }

  preparePageData() {
    this.showLinkUser = false;
    this.message = '';
    this.errorMessage = '';
    switch (this.pageRouteName) {
      case 'link-user':
        if (this.authService.isTokenValidated()) {
            this.showLinkUser = true;
            this.companyName = this.authService.getCompanyName();
            this.email = this.activatedRoute.snapshot.params['emailId'];
            this.code = decodeURIComponent(this.activatedRoute.snapshot.params['code']);
        }
        break;
      case 'verify':
        this.verifyCode();
        break;
    }
  }

  verifyCode(): void {
    const payload = {
      email: this.activatedRoute.snapshot.params['emailId'],
      code: this.activatedRoute.snapshot.params['code'],
      type: this.activatedRoute.snapshot.params['type'] ? this.activatedRoute.snapshot.params['type'] : ''
    }
    this.authService.verify(payload, payload.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data.success) {
          this.authService.setTokenStatus(true);
          if (data.companyId) {
            this.authService.setCompanyId(data.companyId);
            localStorage.setItem('companyId', data.companyId);
          }
          if (data.companyName) {
            this.authService.setCompanyName(data.companyName);
          }
          if (data.name) {
            this.authService.setName(data.name);
          }
          const path = payload.type ? payload.type : 'signup';
          this.router.navigate([`/${path}/${payload.email}/${encodeURIComponent(payload.code)}`]);
        } else {
          // this.errorMessage = ValidTokenErrorMessage;
          this.messageService.create('error', 'Error', ValidTokenErrorMessage, { nzClass: 'error' });
          this.authService.setTokenStatus(false);
        }
      })
  }

  linkUser(accepted: boolean) {
    this.isSubmitted = true;
    const payload = {
      email: this.email,
      code: this.code,
      accepted
    }
    this.authService.linkUser(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.isSubmitted = false;
        this.cdr.markForCheck();
        if (data.success) {
          localStorage.removeItem('companyId');
          const message = accepted ? `User successfully linked to ${this.authService.getCompanyName()} company` : data.message;
          this.messageService.create('success', 'Success', message, { nzClass: 'success' });
          localStorage.clear();
          this.router.navigate([`/login`]);
        } else {
          const message = data.message ? data.message : ServerErrorMessage;
          this.messageService.create('error', 'Error', message, { nzClass: 'error' });
          localStorage.removeItem('companyId');
          localStorage.clear();
        }
      })
  }

}
