import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordValidatorComponent } from './password-validator.component';
import { PasswordValidatorDirective } from './directive/password-validator.directive';
import { PasswordValidatorService } from './services/password-validator.service';

@NgModule({
  declarations: [
    PasswordValidatorComponent,
    PasswordValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  providers: [PasswordValidatorService],
  exports: [
    PasswordValidatorComponent,
    PasswordValidatorDirective
  ]
})
export class PasswordValidatorModule { }
