import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-signup-invite',
  templateUrl: './signup-invite.component.html',
  styleUrls: ['./signup-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupInviteComponent {
  @Input() form: FormGroup;
  @Input() formControls: any;
  @Input() submitIcon: string;
  @Input() cancelIcon: string;
  @Input() isSubmitting: boolean;

  @Output() submitInvite: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToLogin: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  onSubmitForm(): void {
    this.submitInvite.emit();
  }

  onGotoLogin(): void {
    this.navigateToLogin.emit();
  }

}
