import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportService } from './services/report.service';
import { ReportFormService } from './services/report-form.service';
import { ReportRoutingModule } from './report-routing.module';
import { ReportFormViewModule } from './containers/report-form-view/report-form-view.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReportRoutingModule,
    ReportFormViewModule,
  ],
  providers: [ReportService, ReportFormService]
})
export class ReportModule { }
