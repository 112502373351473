import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TakeUntilDestroy } from 'src/app/shared/directives/take-until-destory.directive';
import {  ServerErrorMessage, ValidTokenErrorMessage } from 'src/app/util/common.constants';
import { SignupFormModel } from '../../model/auth.model';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthService } from '../../services/auth.service';
import { takeUntil } from 'rxjs';
import { MaterialIcon } from 'src/app/util/common.enum';

@Component({
  selector: 'app-signup-form-view',
  templateUrl: './signup-form-view.component.html',
  styleUrls: ['./signup-form-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupFormViewComponent extends TakeUntilDestroy implements OnInit {

  form: FormGroup;
  formControls: any;
  errorMessage: string;
  title = 'Signup';
  isLoading: boolean;
  submitIcon: string;
  cancelIcon: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formService: AuthFormService,
    private authService: AuthService,
    private messageService: NzNotificationService,
    private cdr: ChangeDetectorRef
  ) {
    super();
   }

  ngOnInit(): void {
    this.submitIcon = MaterialIcon.ARROW_CIRCLE_RIGHT;
    this.cancelIcon = MaterialIcon.CLOSE_NORMAL;
    if (this.authService.isTokenValidated()) {
      this.form = this.formService.buildSignupForm();
      this.formControls = this.formService.getFormControls(this.form);
      const data = {
        email: this.activatedRoute.snapshot.params['emailId'],
        code: decodeURIComponent(this.activatedRoute.snapshot.params['code']),
        companyName: this.authService.getCompanyName() || '',
        name: this.authService.getName() || ''
      } as SignupFormModel;
      this.formService.setSignupFormValues(this.form, data);
      this.title = 'Signup';
    } else {
      // this.errorMessage = ValidTokenErrorMessage;
      this.messageService.create('error', 'Error', ValidTokenErrorMessage, { nzClass: 'error' });
    }
  }

  onSignupSubmit(): void {
    this.isLoading = true;
    this.authService.signup(this.form.getRawValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.isLoading = false;
        this.cdr.markForCheck();
        if (data.success) {
          localStorage.removeItem('companyId');
          this.messageService.create('success',  'Success', `Signup is successful.`, { nzClass: 'success' });
          localStorage.clear();
          this.router.navigate([`/login`]);
        } else {
          const message = data.message ? data.message : ServerErrorMessage;
          this.messageService.create('error',  'Error', message, { nzClass: 'error' });
        }
      })
  }

  navigateToLogin() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
